import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Logo from '../../images/logos/wavenex.svg';
import LogoIcon from '../../images/logos/wavenex-icon.svg';
import styles from './Header.module.scss';
import { Link, NavLink } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function Header({ onShowContactHandler }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [entering, setEntering] = useState(false);

  useEffect(() => {
    if (dropDown) {
      disableBodyScroll(document.body);
    } else {
      enableBodyScroll(document.body);
    }
  }, [dropDown]);

  const toggleMenuHandler = () => {
    setMenuOpen(!menuOpen);
    if (!dropDown) {
      setDropDown(true);
      setEntering(true);
      setTimeout(() => {
        setEntering(false);
      }, 10);
    } else {
      setEntering(true);
      setTimeout(() => {
        setEntering(false);
        setDropDown(false);
      }, 400);
    }
  };
  const onClickLinkHandler = () => {
    dropDown && toggleMenuHandler();
  };

  return (
    <header className={classnames(dropDown ? styles.expand : '', entering && styles.expandEnter)}>
      <div className={styles.container}>
        <div className={styles.left}>
          <Link to='/'>
            <picture>
              <source media='(min-width: 768px)' srcSet={Logo} />
              <img src={LogoIcon} alt='' />
            </picture>
          </Link>
          <div
            className={classnames(styles.toggle, menuOpen ? styles.open : '')}
            onClick={toggleMenuHandler}>
            <span className={styles.line1}></span>
            <span className={styles.line2}></span>
            <span className={styles.line3}></span>
          </div>
        </div>
        <div className={styles.menu}>
          <NavLink
            to='about'
            className={({ isActive }) => (isActive ? styles.active : '')}
            onClick={onClickLinkHandler}>
            About
          </NavLink>
          <a
            href='https://wavenex.com.hk/solutions/'
            rel='noreferrer'
            onClick={onClickLinkHandler}
          >
            Solutions
          </a>
          <NavLink
            to='showcases'
            className={({ isActive }) => (isActive ? styles.active : '')}
            onClick={onClickLinkHandler}>
            Showcases
          </NavLink>
          <NavLink
            to='services'
            className={({ isActive }) => (isActive ? styles.active : '')}
            onClick={onClickLinkHandler}>
            Services
          </NavLink>
          <NavLink
            to='clients'
            className={({ isActive }) => (isActive ? styles.active : '')}
            onClick={onClickLinkHandler}>
            Clients
          </NavLink>
          <a
            href='https://join.wavenex.com.hk'
            target='_blank'
            rel='noreferrer'
            onClick={onClickLinkHandler}>
            Join Us
          </a>
        </div>
        <div className={styles.right}>
          <button
            onClick={() => {
              ReactGA.event({
                category: 'contact',
                action: 'button click',
                label: 'header',
              });
              onClickLinkHandler();
              onShowContactHandler();
            }}>
            Contact Us
          </button>

        </div>
      </div>
    </header>
  );
}

import React from 'react';
import LogoSlogan from '../../images/logos/wavenex-slogan.png';
import LogoText from '../../images/logos/wavenex-text.png';
import styles from './Footer.module.scss';
import { Link } from 'react-router-dom';

export default function Header() {
  return (
    <footer>
      <div className={styles.container}>
        <div className={styles.company}>
          <Link to='/'>
            <img src={LogoText} alt='' />
          </Link>
          <div className={styles.text}>
            Wavenex is a full-service global digital solution consultant. We are experts
            at enabling companies towards digital transformation that truly targets local,
            regional as well as the international digital audience.
          </div>
        </div>
        <div className={styles.sitemap}>
          <Link to='about'>About</Link>
          <a href='https://wavenex.com.hk/solutions' rel='noreferrer'>
            Solutions
          </a>
          <Link to='showcases'>Showcases</Link>
          <Link to='services'>Services</Link>
          <Link to='clients'>Clients</Link>
          <a href='https://join.wavenex.com.hk' target='_blank' rel='noreferrer'>
            Join Us
          </a>
        </div>
        <div className={styles.contact}>
          <div className={styles.detail}>
            <p className={styles.head}>Email: </p>
            <a className={styles.content} href='mailto:info@wavenex.com.hk'>
              info@wavenex.com.hk
            </a>
          </div>
          <div className={styles.detail}>
            <p className={styles.head}>Phone:</p>
            <a className={styles.content} href='tel:+852 3586 2806'>
              +852 3586 2806
            </a>
          </div>
          <div className={styles.detail}>
            <p className={styles.head}>Address:</p>
            <a className={styles.content} href='tel:+852 3586 2806'>
              10/F, Kin Sang Commercial Centre, <br />
              49 King Yip Street, Kwun Tong<br />
              (by appointment)
            </a>
          </div>
        </div>
        <div className={styles.icon}>
          <img src={LogoSlogan} alt='' />
        </div>
      </div>
    </footer>
  );
}

import React, { useEffect, useState, Suspense, lazy } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { animateScroll } from 'react-scroll';
import { ToastContainer } from 'react-toastify';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Footer from './components/footer';
import Header from './components/header';
import ReactGA from 'react-ga4';
import 'react-toastify/dist/ReactToastify.css';
import './styles/global.scss';

const About = lazy(() => import('./pages/about'));
const Showcases = lazy(() => import('./pages/showcases'));
const Blog = lazy(() => import('./pages/blog'));
const Clients = lazy(() => import('./pages/clients'));
const Home = lazy(() => import('./pages/home'));
const Service = lazy(() => import('./pages/service'));
const Download = lazy(() => import('./pages/download'));
const Demo = lazy(() => import('./pages/demo'));
// const Unreachable = lazy(() => import('./pages/unreachable'));
const ContactForm = lazy(() => import('./components/contactForm'));

function App() {
  let location = useLocation();
  const [showContact, setShowContact] = useState(false);

  const onShowContactHandler = () => {
    setShowContact(true);
    document.body.classList.add('freeze');
  };
  const onCloseContactHandler = () => {
    setShowContact(false);
    document.body.classList.remove('freeze');
  };

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
    location.hash === '' && animateScroll.scrollToTop();
  }, [location]);

  useEffect(() => {
    const onKeydownHandler = event => {
      event.key === 'Escape' && onCloseContactHandler();
    };
    document.addEventListener('keydown', onKeydownHandler);
    return () => {
      document.removeEventListener('keydown', onKeydownHandler);
    };
  }, []);

  return (
    <div className='App'>
      <Header onShowContactHandler={onShowContactHandler} />
      <main className={showContact ? 'freeze' : ''}>
        <TransitionGroup>
          <CSSTransition
            key={location.key}
            classNames="fade"
            timeout={300}
          >
            <Suspense fallback={<div>Loading...</div>}>
              <Routes location={location}>
                <Route path='/' element={<Home />} />
                <Route path='about' element={<About />} />
                <Route path='showcases' element={<Showcases />} />
                <Route path='services' element={<Service />} />
                <Route path='clients' element={<Clients />} />
                <Route path='blog' element={<Blog />} />
                <Route path='download/:filename' element={<Download />} />
                <Route path='demo/:slug' element={<Demo />} />
                {/* <Route path='unreachable' element={<Unreachable />} /> */}
                <Route path='*' element={<Navigate to='/' replace={true} />} />
              </Routes>
            </Suspense>
          </CSSTransition>
        </TransitionGroup>
      </main>
      <div className='end'></div>
      <Footer />
      <ContactForm showContact={showContact} onCloseContactHandler={onCloseContactHandler} />
      <ToastContainer />
    </div>
  );
}

export default App;
